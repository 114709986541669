`<template>
  <div class=" animated fadeIn ">

    <div class="row" v-if=" carriersList.length > 0 && !loading.data ">

      <div class="col-12 d-md-none">
        <div class="row">
          <div class="col-12" :class=" carrier.active ? '' : 'item-offline' " v-for=" (carrier,index) in carriersList "
            :key=" 'mobile' + index">
            <div class=" bg-light mb-3 p-3">
              <div class="row align-items-center">
                <div class="col-auto ">
                  <img :class=" 'bg-carrier-' + carrier.name " class="img-cover-40 bg-carrier-defalut border-none "
                    alt="">
                </div>
                <div class="col">
                  <p>
                    <strong :class=" carrier.active ?  'text-success' : 'text-muted' "
                      v-text=" (carrier.active ? $t('general.online'): $t('general.offline') )  ">
                    </strong>
                  </p>
                  <div class="row justify-content-end align-items-center">
                    <div class="col-auto">
                      <button class="btn btn-success rounded-pill  "
                        @click="carrier.showServices = !carrier.showServices">
                        <span v-text="$t('general.form.services')"></span>

                        <transition name="custom-classes-transition" mode="out-in" enter-active-class="animated jello">
                          <i class="ml-2 fa fa-caret-up" v-if="carrier.showServices" key="open"></i>
                          <i class="ml-2 fa fa-caret-down" v-if="!carrier.showServices" key="close"></i>
                        </transition>

                      </button>
                    </div>
                    <div class="col-auto pl-0">
                      <button class="btn btn-success p-0 circle-35 " @click="fnApiUpdateCarrier(index)">
                        <i class="fa fa-power-off"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" mt-4 animated fadeIn" v-show="carrier.showServices">
                <table class="table table-striped ">
                  <tbody>
                    <tr v-for=" (service,indexS) in carrier.services " :key=" indexS ">
                      <td>
                        <div>
                          <div class="row justify-content-between align-items-center ">
                            <div class="col-10">
                              <label :for="service.id" class="m-0 w-100">
                                <span
                                  v-text=" $te('general.carriers.services.' + service.name) ? $t('general.carriers.services.' + service.name) : service.name  "></span>
                                <br>
                                <span class="text-muted" v-if="$te('general.carriers.services.' + service.name)">
                                  <small>
                                    <i class="fa fa-info-circle mr-1"></i> <span
                                      v-text=" $t('general.carriers.services.' + service.name + '_description') "></span>
                                  </small>
                                </span>
                              </label>
                            </div>
                            <div class="col-auto">
                              <input type="checkbox" name="" :id="service.id " v-model="service.active"
                                :disabled="!carrier.active" @click=" fnApiUpdateService( [index , indexS] ) ">
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>





            </div>
          </div>
        </div>

      </div>
      <div class="col-12 d-none d-md-block">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4 " v-for=" (carrier,index) in carriersList "
            :key="index" :class=" carrier.active ? '' : 'item-offline' ">
            <div class="card h-100 card-ecart ">
              <div class="card-header border-bottom-0">
                <div class="row align-items-center justify-content-between ">
                  <div class="col-8">
                    <h6 class="text-capitalize text-truncate">
                      <span v-text=" carrier.name "></span>
                      <small>
                        <strong> - </strong>
                        <strong :class=" carrier.active ?  'text-success' : 'text-muted' "
                          v-text=" (carrier.active ? $t('general.online'): $t('general.offline') )  ">
                        </strong>
                      </small>
                    </h6>
                  </div>
                  <div class="col-auto">
                    <div class="media-body text-right switch-sm">
                      <label class="switch">
                        <input type="checkbox" :checked="carrier.active"><span class="switch-state "
                          :class=" carrier.active ? 'bg-success': '' " @click=" fnApiUpdateCarrier(index) "></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <img :class=" 'bg-carrier-' + carrier.name " class=" bg-carrier-defalut border-none " alt="">
              <div class="h-100">
                <!-- <hr class="my-2"> -->
                <div class="row animated fadeIn">
                  <div class="col-12">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th v-text="$t('dashboard.shipping.servicesMessage')"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for=" (service,indexS) in carrier.services " :key=" indexS ">
                          <td>
                            <div>
                              <div class="row justify-content-between ">
                                <div class="col-10">
                                  <label :for="service.id" class="m-0"
                                    v-text=" $te('general.carriers.services.' + service.name) ? $t('general.carriers.services.' + service.name) : service.name  ">
                                  </label>
                                </div>
                                <div class="col-auto">
                                  <input type="checkbox" name="" :id="service.id " v-model="service.active"
                                    :disabled="!carrier.active" @click=" fnApiUpdateService( [index , indexS] ) ">
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-10 ">
                                  <p class="text-muted" v-if="$te('general.carriers.services.' + service.name)">
                                    <small>
                                      <i class="fa fa-info-circle mr-1"></i> <span
                                        v-text=" $t('general.carriers.services.' + service.name + '_description') "></span>
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <no-data :dataMessage="$t('noDataMessages.noInformaton')" :isLoading="loading.data" :showBtn="false" v-else>
    </no-data>

    <!-- <div class="card-header ">
      <p class="m-0"> <small v-text="$t('dashboard.shipping.carriersServicesMessage')"></small> </p>
      <div class=" d-flex justify-content-between align-items-center  ">
        <h5 class=" " v-text=" $t('components.dashboard.shipping.carriers.title' ) "> </h5>
        <button class=" btn btn-sm btn-primary " @click=" showCarriers = !showCarriers "
          v-text=" showCarriers ? $t('general.hide' ): $t('general.show' ) ">
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
  import {
    mapActions,
    mapState
  } from 'vuex';
  export default {
    data() {
      return ({})
    },
    computed: {
      ...mapState('EcartCarriers', ['carriersList', 'loading']),
    },
    methods: {
      ...mapActions('EcartCarriers', ['fnApiGetCarriers', 'fnApiUpdateService', 'fnApiUpdateCarrier']),
    },
    async mounted() {
      await this.fnApiGetCarriers();
    }
  }
</script>

<style>
  .card-carrier {
    height: 200px !important;
  }
</style>`